<template>
    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
            <div class="card-title m-0">
                <h3 class="fw-boldest m-0">{{ $t('pages.account.settings.signInMethod.title') }}</h3>
            </div>
        </div>

        <div id="kt_account_signin_method" class="collapse show">
            <div class="card-body border-top p-9">
                <div class="d-flex flex-wrap align-items-center mb-8">
                    <div id="kt_signin_email" :class="{ 'd-none': email.form.display }">
                        <div class="fs-4 fw-boldest mb-1">{{ $t('pages.account.settings.signInMethod.emailAddress') }}</div>
                        <div class="fs-6 fw-bold text-gray-600">{{ $root.currentUser.email }}</div>
                    </div>

                    <!--<div id="kt_signin_email_edit" :class="{ 'd-none': !emailFormDisplay }" class="flex-row-fluid">
                        <Form id="kt_signin_change_email" class="form" novalidate="novalidate" @submit="updateEmail()" :validation-schema="changeEmail">
                            <div class="row mb-6">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="fv-row mb-0">
                                        <label for="emailaddress" class="form-label fs-6 fw-bolder mb-3">Enter New Email Address</label>
                                        <Field type="email" class="form-control form-control-lg form-control-solid fw-bold fs-6" id="emailaddress" placeholder="Email Address" name="emailaddress" value="support@keenthemes.com"/>
                                        <div class="fv-plugins-message-container">
                                            <div class="fv-help-block">
                                                <ErrorMessage name="emailaddress"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="fv-row mb-0">
                                        <label for="confirmemailpassword" class="form-label fs-6 fw-bolder mb-3">Confirm Password</label>
                                        <Field type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="confirmemailpassword" id="confirmemailpassword"/>
                                        <div class="fv-plugins-message-container">
                                            <div class="fv-help-block">
                                                <ErrorMessage name="confirmemailpassword"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button id="kt_signin_submit" type="submit" ref="updateEmailButton" class="btn btn-primary me-2 px-6">
                                    <span class="indicator-label"> Update Email </span>
                                    <span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                                <button id="kt_signin_cancel" type="button" class="btn btn-color-gray-400 btn-active-light-primary px-6" @click="emailFormDisplay = !emailFormDisplay">
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    </div>
                    <div id="kt_signin_email_button" :class="{ 'd-none': emailFormDisplay }" class="ms-auto">
                        <button class="btn btn-light fw-boldest px-6" @click="emailFormDisplay = !emailFormDisplay">
                            Change Email
                        </button>
                    </div>-->
                </div>
                <!--end::Email Address-->

                <!--begin::Password-->
                <div class="d-flex flex-wrap align-items-center mb-8">
                    <div id="kt_signin_password" :class="{ 'd-none': password.form.display }">
                        <div class="fs-4 fw-boldest mb-1">{{ $t('pages.account.settings.signInMethod.password.title') }}</div>
                        <div class="fs-6 fw-bold text-gray-600">************</div>
                    </div>
                    <div id="kt_signin_password_edit" class="flex-row-fluid" :class="{ 'd-none': !password.form.display }">
                        <!--<div class="fs-6 fw-bold text-gray-600 mb-4">
                            Password must be at least 8 character and contain symbols
                        </div>-->

                        <!--begin::Form-->
                        <el-form @submit.prevent="onChangePasswordSubmit()" :model="password.form.data" ref="changePasswordForm">
                            <div class="row mb-6">
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label class="form-label fs-6 fw-bolder fw-bolder mb-3">
                                            {{ $t('pages.account.settings.signInMethod.password.cols.currentPassword') }}
                                        </label>
                                        <el-form-item prop="current_password" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="password.form.data.current_password" ref="currentPassword" autocomplete="off" type="password"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label class="form-label fs-6 fw-bolder fw-bolder mb-3">
                                            {{ $t('pages.account.settings.signInMethod.password.cols.password') }}
                                        </label>
                                        <el-form-item prop="password" :rules="password.form.rules.password">
                                            <el-input v-model="password.form.data.password" ref="password" autocomplete="off" type="password"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label class="form-label fs-6 fw-bolder fw-bolder mb-3">
                                            {{ $t('pages.account.settings.signInMethod.password.cols.passwordConfirmation') }}
                                        </label>
                                        <el-form-item prop="password_confirmation" :rules="password.form.rules.password_confirmation">
                                            <el-input v-model="password.form.data.password_confirmation" ref="passwordConfirmation" autocomplete="off" type="password"/>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button :data-kt-indicator="password.form.loading ? 'on' : null" class="btn btn-primary me-2 px-6" type="submit" :disabled="password.form.loading">
                                    <span v-if="!password.form.loading" class="indicator-label">{{ $t('pages.account.settings.signInMethod.password.updatePassword') }}</span>
                                    <span v-if="password.form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                                <button type="button" @click="password.form.display = !password.form.display" class="btn btn-color-gray-400 btn-active-light-primary px-6">
                                    {{ $t('btn.cancel') }}
                                </button>
                            </div>
                        </el-form>
                        <!--end::Form-->
                    </div>
                    <div class="ms-auto" :class="{ 'd-none': password.form.display }">
                        <button type="button" @click="showChangePasswordForm" class="btn btn-light fw-boldest">
                            {{ $t('pages.account.settings.signInMethod.password.changePassword') }}
                        </button>
                    </div>
                </div>
                <!--end::Password-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Content-->
    </div>
</template>

<script>

export default {
    name: "settings",
    data() {
        return {
            email: {
                form: {
                    display: false
                }
            },
            password: {
                form: {
                    display: false,
                    title: '',
                    loading: false,
                    updateStatus: false,
                    rules: {},
                    data: {}
                }
            }
        }
    },
    created() {
        this.password.form.rules = {
            password: [{validator: this.validatePass, trigger: ['blur']}],
            password_confirmation: [{validator: this.validatePass2, trigger: ['blur', 'change']}],
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("common.settings"), [this.$t("menu.accountManagement")]);

        this.initializePasswordForm();
    },
    methods: {
        initializePasswordForm() {
            this.$refs.currentPassword.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
            this.$refs.password.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
            this.$refs.passwordConfirmation.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
        },
        showChangePasswordForm() {
            this.password.form.data = {
                password: '',
                password_confirmation: ''
            };
            this.password.form.display = true;
        },
        onChangePasswordSubmit() {
            this.$refs.changePasswordForm.validate((valid) => {
                if (valid) {
                    this.password.form.loading = true;
                    this.axios.post(this.endpoint.get('auth_change_password'), this.password.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.password.form.display = false;
                        }, () => {
                            this.password.form.loading = false;
                        });
                    }).catch(error => {
                        if (error.response.data.message == 'current_password_invalid') {
                            error.response.data.message = this.$t('pages.account.settings.signInMethod.password.responseMessages.currentPasswordInvalid');
                        }

                        this.onResponseFailure(error.response.data, () => {
                            this.password.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.password')))
            } else {
                if (this.password.form.data.password_confirmation !== '') {
                    this.$refs.changePasswordForm.validateField('password_confirmation')
                }
                callback()
            }
        },
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.passwordConfirmation')))
            } else if (value !== this.password.form.data.password) {
                callback(new Error(this.$t('validation.dontMatchPassword')))
            } else {
                callback()
            }
        }
    }
}
</script>
